
import { Component, Vue } from 'vue-property-decorator'
import { ProductDetail, ProductExportInfo, ProductExportDetail } from '@/types/product.d'

@Component({
  name: 'AgentAdd'
})
export default class extends Vue {
  private productTypeList: Array<{ productTypeId: string; productTypeName: string }> = []
  private searchInfo = {
    keyWord: '',
    productTypeId: ''
  }

  private isLoading = false
  private info: Array<ProductDetail> = []
  private selectList: Array<ProductDetail> = []
  private isShowExport = false
  private quotaPriceStatus = '0'
  private operatorInfo = {
    date: '',
    phone: '',
    userName: ''
  }

  private exportInfo: Array<ProductExportInfo> = []
  private totalProduct = {
    productCountTotal: 0,
    quotaPriceTotal: '0.00'
  }

  get exportTitleUnitWidth () {
    return this.quotaPriceStatus === '0' ? '220px' : '164px'
  }

  created (): void {
    this.getProductTypeList()
    this.getData()
  }

  getProductTypeList () {
    this.$axios.get(this.$apis.product.selectProductTypeByPage).then((res) => {
      this.productTypeList = res.list || []
    })
  }

  // 加载表格
  getData () {
    this.isLoading = true
    this.$axios.get(this.$apis.product.selectNewProductByList, this.searchInfo).then((res) => {
      this.info = res || []
    }).finally(() => {
      this.isLoading = false
    })
  }

  // 加载子产品详情
  loadChild (row: ProductDetail, treeNode: any, resolve: Function) {
    this.$axios.get(this.$apis.product.selectNewProductByPage, {
      parentProductId: row.productId
    }).then((res) => {
      resolve(res.list || [])
    })
  }

  // 单个勾选
  handleSelectionRowChange (arr: Array<ProductDetail>, row: ProductDetail) {
    const isSelect = arr.findIndex(item => item.productId === row.productId) >= 0
    const cIndex = this.selectList.findIndex(item => item.productId === row.productId)
    if (isSelect) {
      if (cIndex >= 0) {
        this.$message.info('该产品已经选过了')
      } else {
        this.selectList.push(row)
      }
    } else if (cIndex >= 0) {
      this.selectList.splice(cIndex, 1)
    }
  }

  // 全部勾选
  handleSelectionAllChange (arr: Array<ProductDetail>) {
    // 如果表格第一个被选中，则是全选，否则是取消全选
    const isSelectAll = arr.findIndex(item => item.productId === this.info[0].productId) >= 0
    if (isSelectAll) {
      const arrNotIn: Array<ProductDetail> = []
      this.info.forEach(obj => {
        if (this.selectList.findIndex(item => item.productId === obj.productId) === -1) {
          arrNotIn.push(obj)
        }
      })
      this.selectList = this.selectList.concat(arrNotIn)
    } else {
      // 取消全选，则删除当前表格中的所有已选中的产品
      this.info.forEach(obj => {
        const index = this.selectList.findIndex(item => item.productId === obj.productId)
        if (index >= 0) {
          this.selectList.splice(index, 1)
        }
      })
    }
  }

  // 删除已勾选的
  delectSelect (index: number) {
    this.selectList.splice(index, 1)
  }

  // 下一步
  onExportShow () {
    if (this.selectList && this.selectList.length > 0) {
      const loading = this.$loading({
        lock: true,
        text: '表格生成中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const idList: Array<string> = []
      this.selectList.forEach(item => {
        idList.push(item.productId as string)
      })
      this.$axios.post(this.$apis.product.previewExcel, idList).then((res) => {
        this.quotaPriceStatus = res.quotaPriceStatus
        this.operatorInfo = res.operatorInfo
        this.exportInfo = res.products
        this.totalProduct = res.totalProduct
        this.isShowExport = true
        loading.close()
      })
    } else {
      this.$message.warning('请选出产品')
    }
  }

  // 数量变更
  productCountChange (currentValue: number, oldValue: number, row: ProductExportDetail, index: number, rowIndex: number) {
    const difference = currentValue - oldValue
    const priceDiff = Number((Number(row.quotaPrice) * 100 * difference / 100).toFixed(2))
    this.exportInfo[index].productCountTotal += difference
    this.totalProduct.productCountTotal += difference
    this.exportInfo[index].list[rowIndex].quotaTotalPrice = this.pricePlus(Number(this.exportInfo[index].list[rowIndex].quotaTotalPrice), priceDiff)
    this.exportInfo[index].quotaPriceTotal = this.pricePlus(Number(this.exportInfo[index].quotaPriceTotal), priceDiff)
    this.totalProduct.quotaPriceTotal = this.pricePlus(Number(this.totalProduct.quotaPriceTotal), priceDiff)
  }

  pricePlus (value: number, plusValue: number) {
    return ((value * 100 + plusValue * 100) / 100).toFixed(2)
  }

  // 导出
  onExport () {
    if (this.selectList && this.selectList.length > 0) {
      const loading = this.$loading({
        lock: true,
        text: '下载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const idList: Array<{ productId: string; productCount: number }> = []
      this.exportInfo.forEach(item => {
        item.list.forEach(cItem => {
          idList.push({
            productId: cItem.productId,
            productCount: cItem.productCount
          })
        })
      })

      this.$axios.downloadFile(this.$apis.product.downloadExcel, idList).then(() => {
        loading.close()
        this.$message.success('下载成功')
        this.$router.push({ name: 'productList' })
      })
    } else {
      this.$message.warning('请勾选要导出的产品')
    }
  }
}
